
import { defineComponent, reactive, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
// import LangSelector from "@/components/LangSelector.vue";
import { i18n } from "@/i18n";

export default defineComponent({
  name: "Login",
  components: {
    // LangSelector
  },
  setup() {
    const { dispatch } = useStore();
    const router = useRouter();
    const { t } = i18n.global;
    const currentYear = new Date().getFullYear();

    const submitErrorMessage = ref("");

    const username = reactive({
      value: "",
      classes: [""],
      message: ""
    });

    const password = reactive({
      value: "",
      classes: [""],
      message: ""
    });

    const submit = () => {
      username.classes = [];
      password.classes = [];

      // TODO: validate inputs

      dispatch("login", { username: username.value, password: password.value })
        .then(() => {
          router.push({ name: "Home" });
        })
        .catch(errs => {
          errs.forEach((err: any) => {
            if (!err.field) {
              submitErrorMessage.value = t("login.invalidCredentials");
              if (err.code === 11) {
                username.classes = ["border-red-600"];
                password.classes = ["border-red-600"];
              }
            }
          });
        });
    };

    return { t, submit, username, password, submitErrorMessage, currentYear };
  }
});
